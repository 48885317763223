import React from 'react';

function Menu(props) {
    const MenuClassName = (
        `menu ${props.isOpenMenu ? 'menu_opened' : ''}`
    );

    return (
        <div className={MenuClassName}>
            <div className="menu__container">
                <button className="menu__close-button" type="button" onClick={props.closeMenu}></button>
                <div className="menu__navigation">
                    <div className="menu__navigation_block-top">
                        <a className="menu__navigation_link" href={'#about'}>
                            <button className="menu__navigation__block-top-button">О нас</button>
                        </a>
                        <a className="menu__navigation_link" href={'#repertoire'}>
                            <button className="menu__navigation__block-top-button">Репертуар</button>
                        </a>
                        <a className="menu__navigation_link" href={'#form'}>
                            <button className="menu__navigation__block-top-button">Контакты</button>
                        </a>
                        <a className="menu__navigation_link" href={'#reviews'}>
                            <button className="menu__navigation__block-top-button">Отзывы</button>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;