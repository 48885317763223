import show from '../../images/show.svg';
import welcome from '../../images/welcome.svg';
import acts from '../../images/acts.svg';
import plays from '../../images/plays.svg';
import heart1 from '../../images/heart1.svg';
import heart2 from '../../images/heart2.svg';
import heart3 from '../../images/heart3.svg';
import heart1m from '../../images/heart1-mobile.svg';
import heart2m from '../../images/heart2-mobile.svg';
import heart3m from '../../images/heart3-mobile.svg';
import sidearc from '../../images/sidearc.svg';

function Navigation() {
    return (
        <nav className="navigation__container position-relative">
            <img className="navigation__heart-one" src={heart1} alt=''/>
            <img className="navigation__heart-two" src={heart2} alt=''/>
            <img className="navigation__heart-three" src={heart3} alt=''/>
            <img className="navigation__heart-one-mobile" src={heart1m} alt=''/>
            <img className="navigation__heart-two-mobile" src={heart2m} alt=''/>
            <img className="navigation__heart-three-mobile" src={heart3m} alt=''/>
            <img className="navigation__sidearc" src={sidearc} alt=''/>
            <div className='flex-row flex-space_between flex-container navigation__links-container'>
                <a className="navigation__picture" href={'#repertoire'}>
                    <img className='navigation__image' src={show}
                         alt='mime'/>
                </a>
                <a className="navigation__picture" href={'#welcome'}>
                    <img className='navigation__image' src={welcome}
                         alt='mime'/>
                </a>
                <a className="navigation__picture" href={'#acts'}>
                    <img className='navigation__image' src={acts}
                         alt='mime'/>
                </a>
                <a className="navigation__picture" href={'#plays'}>
                    <img className='navigation__image' src={plays}
                         alt='mime'/>
                </a>
            </div>
        </nav>
    );
}

export default Navigation;