import one from '../../images/heart1.svg'
import two from '../../images/heart2.svg'
import three from '../../images/heart3.svg'
import hearts from '../../images/сердешки.svg'
import linetop from '../../images/revlinestop.svg'
import linebot from '../../images/revlinesbottom.svg'
import {CustomSlider} from "../Slider/Slider";

function Reviews() {
    return (
        <div id='reviews' className='position-relative'>
            <img className='reviews__heart-one' src={one} alt='heart'/>
            <img className='reviews__heart-two' src={two} alt='heart'/>
            <img className='reviews__heart-three' src={three} alt='heart'/>

            <div className='reviews__container'>
                <div className='position-relative reviews__header-container'>
                    <img className='reviews__hearts' src={hearts} alt='hearts'/>
                    <h2 className='reviews__header'>Отзывы</h2>
                    <img className='reviews__line-top' src={linetop} alt=''/>
                    <img className='reviews__line-bot' src={linebot} alt=''/>
                </div>
                <div className='slider-container'>
                <CustomSlider customArrowNextClassList={'custom-arrow-next'}
                                  customArrowPrevClassList={'custom-arrow-prev'}/>
                </div>
            </div>

        </div>
    );
}

export default Reviews;